import axios from 'axios';
import { DocumentNode, print } from 'graphql';
import { useCallback } from 'react';
import { GetDataParams, GetDataResult } from '@letmedev/neru';

export function fetcher<R, V>(
  doc: DocumentNode,
  vars?: V,
): Promise<R> {
  return axios
    .post(
      process.env.REACT_APP_GRAPHQL_URL,
      {
        query: print(doc),
        variables: vars,
      },
    )
    .then(({ data }) => {
      if (data.errors) {
        const { message } = data.errors[0];
        throw new Error(message);
      }
      return data.data;
    })
    .catch((e) => {
      if (e?.data?.errors) {
        throw new Error(e.data.errors[0]);
      }
      throw e;
    });
}

type AsyncApi<T> = (...p:any) => Promise<T>;

export function useGridFetcher<T>(
  api: AsyncApi<T>,
  resolver: (p: T) => GetDataResult,
) {
  return useCallback<(props: GetDataParams) => Promise<GetDataResult>>(({
    filter, sort, limit, offset,
  }) => (
    api({
      filter: filter ? JSON.stringify(filter) : '',
      sort: sort ? JSON.stringify(sort) : '',
      limit: limit || 0,
      offset: offset || 0,
    })
  )
    .then(resolver), [api, resolver]);
}
