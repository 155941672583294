import {
  useCallback, useMemo, useState, useEffect, FC, PropsWithChildren,
} from 'react';
import { ThemeProvider } from '@mui/material';
import axios from 'axios';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { AppContext, AppContextProvider, User } from './common/AppContext';
import { setSSID, getSSID, api } from './common/auth';
import { theme } from './mui-theme';
import { SplashLoader } from './common/components/SplashLoader/SplashLoader';
import { withLoading } from './common/utils/withLoading';
import { Routes as AdminRoutes } from './pages/Admin';

// Routes
export const routes = createRoutesFromElements(
  <Route path="/">
    <Route path="admin">{AdminRoutes}</Route>
    <Route path="/" lazy={() => import('./pages/Landing')} />
  </Route>,
);

export const App: FC<PropsWithChildren> = ({ children }) => {
  // Loading
  const [loading, setLoading] = useState<boolean>(true);
  const [loginPopup, setLoginPopup] = useState<boolean>(false);

  // User
  const [user, setUser] = useState<User | null>(null);
  const [ssid, setSSIDLocal] = useState(getSSID());
  const setUserContext = useCallback((u: Partial<User> | null) => {
    setUser((prev) => (u ? { ...(prev || {}), ...u } : null));
  }, []);

  // Add axios config
  useEffect(() => {
    const interceptor = axios
      .interceptors
      .response
      .use(
        (response) => {
          const jwt = response.headers['x-jwt-assertion'];
          if (jwt) {
            setSSID(jwt);
            setSSIDLocal(jwt);
          }
          return response;
        },
        (error) => {
          if ('__CANCEL__' in error) {
            return Promise.reject(error);
          }
          if (error.response && error.response.status === 401) {
            setUser(null);
            setSSID('');
          }
          return Promise.reject(error);
        },
      );
    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  // Logout
  const logout = useCallback(() => {
    setUser(null);
    setSSID('');
    setSSIDLocal('');
  }, []);

  // Get user
  const getUser = useCallback(async () => {
    try {
      const res = await withLoading(api.profile, setLoading);
      setUser(res.profile);
    } catch (e) {
      logout();
    }
  }, [logout]);

  // Context
  const context = useMemo<AppContext>(
    () => ({
      user,
      setUser: setUserContext,
      getUser,
      loading,
      setLoading,
      logout,
      loginPopup,
      setLoginPopup,
    }),
    [user, setUserContext, getUser, loading, logout, loginPopup],
  );

  // Get user if authenticated
  const authenticated = !!ssid;
  const userExists = !!user;
  useEffect(() => {
    if (!authenticated || !userExists) {
      getUser();
    }
  }, [authenticated, getUser, userExists]);

  return (
    <AppContextProvider value={context}>
      <ThemeProvider theme={theme}>
        {loading && <SplashLoader />}
        {children}
      </ThemeProvider>
    </AppContextProvider>
  );
};
