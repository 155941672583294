import noop from 'lodash/noop';

export async function withLoading<T>(
  fetcher: () => PromiseLike<T>,
  loader: (v: boolean) => void = noop,
  error: (e: Error | null) => void = noop,
) {
  loader(true);
  error(null);
  try {
    const result = await fetcher();
    loader(false);
    return result;
  } catch (e) {
    loader(false);
    error(e as Error);
    throw e;
  }
}
