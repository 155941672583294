import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    onlyText: true;
  }
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}

export const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'onlyText' },
          style: {
            padding: 0,
            textTransform: 'none',
            fontSize: 'inherit',
          },
        },
        {
          props: { variant: 'onlyText', disabled: true },
          style: {
            padding: 0,
            color: '#eee',
            textTransform: 'none',
            fontSize: 'inherit',
          },
        },
        {
          props: { size: 'xl' },
          style: {
            padding: '12px 24px',
            textTransform: 'none',
            fontSize: '18px',
          },
        },
      ],
      defaultProps: {
        size: 'large',
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        contained: {
          textTransform: 'none',
          borderRadius: '8px',
          fontSize: '14px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#F88B05',
    },
    error: {
      main: '#E30613',
    },
    success: {
      main: '#F88B05',
    },
  },
});
