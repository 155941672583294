import {
  createContext, useContext, FC, PropsWithChildren,
} from 'react';
import { User as AuthUser } from '../graphql-api/types.generated';

export type User = Partial<AuthUser>;

export type AppContext = {
  user: User | null;
  setUser: (u: Partial<User> | null) => void;
  getUser: () => Promise<void>;
  loading: boolean;
  setLoading: (v: boolean) => void;
  logout: () => void;
  loginPopup: boolean;
  setLoginPopup: (v: boolean) => void;
};

export const Context = createContext<AppContext | null>(null);

export interface AppContextProviderProps {
  value: AppContext;
}

export const AppContextProvider: FC<PropsWithChildren<AppContextProviderProps>> = (
  { value, children },
) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export const useAppContext = () => {
  const store = useContext(Context);
  if (!store) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return store;
};
