import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import { AppSpa } from './AppSpa';
import './common';

if (process.env.REACT_APP_TAPE === 'SSR') {
  hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <AppSpa />,
  );
} else {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <React.StrictMode>
      <AppSpa />
    </React.StrictMode>,
  );
}
