import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  initSSID, getSSID,
} from './common/auth';
import { App, routes } from './App';

initSSID(getSSID());

// Router
const browserRouter = createBrowserRouter(routes);

export const AppSpa: FC = () => (
  <App>
    <RouterProvider router={browserRouter} />
  </App>
);
