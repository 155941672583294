import * as Types from '../../graphql-api/types.generated';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type UserFragmentFragment = { __typename?: 'User', user_name: string, is_active: boolean, user_uuid: string, user_email?: string | null, user_phone?: string | null, customer_uuid?: string | null, user_ts: any, roles: Array<string> };

export type AuthQueryVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
}>;


export type AuthQuery = { __typename?: 'Query', auth: { __typename?: 'AuthResult', code: number, exist: boolean, hasPassword: boolean } };

export type AuthSmsQueryVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  code: Types.Scalars['Int']['input'];
}>;


export type AuthSmsQuery = { __typename?: 'Query', authSms: { __typename?: 'User', user_name: string, is_active: boolean, user_uuid: string, user_email?: string | null, user_phone?: string | null, customer_uuid?: string | null, user_ts: any, roles: Array<string> } };

export type AuthPasswordQueryVariables = Types.Exact<{
  phone: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;


export type AuthPasswordQuery = { __typename?: 'Query', authPassword: { __typename?: 'User', user_name: string, is_active: boolean, user_uuid: string, user_email?: string | null, user_phone?: string | null, customer_uuid?: string | null, user_ts: any, roles: Array<string> } };

export type ProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'User', user_name: string, is_active: boolean, user_uuid: string, user_email?: string | null, user_phone?: string | null, customer_uuid?: string | null, user_ts: any, roles: Array<string> } };

export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  user_name
  is_active
  user_uuid
  user_email
  user_phone
  customer_uuid
  user_ts
  roles
}
    `;
export const AuthDocument = gql`
    query auth($phone: String!) {
  auth(phone: $phone) {
    code
    exist
    hasPassword
  }
}
    `;
export const AuthSmsDocument = gql`
    query authSms($phone: String!, $name: String, $code: Int!) {
  authSms(phone: $phone, name: $name, code: $code) {
    ...userFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export const AuthPasswordDocument = gql`
    query authPassword($phone: String!, $password: String!) {
  authPassword(login: $phone, password: $password) {
    ...userFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export const ProfileDocument = gql`
    query profile {
  profile {
    user_name
    is_active
    user_uuid
    user_email
    user_phone
    customer_uuid
    user_ts
    roles
  }
}
    `;
export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    auth(variables: AuthQueryVariables, options?: C): Promise<AuthQuery> {
      return requester<AuthQuery, AuthQueryVariables>(AuthDocument, variables, options) as Promise<AuthQuery>;
    },
    authSms(variables: AuthSmsQueryVariables, options?: C): Promise<AuthSmsQuery> {
      return requester<AuthSmsQuery, AuthSmsQueryVariables>(AuthSmsDocument, variables, options) as Promise<AuthSmsQuery>;
    },
    authPassword(variables: AuthPasswordQueryVariables, options?: C): Promise<AuthPasswordQuery> {
      return requester<AuthPasswordQuery, AuthPasswordQueryVariables>(AuthPasswordDocument, variables, options) as Promise<AuthPasswordQuery>;
    },
    profile(variables?: ProfileQueryVariables, options?: C): Promise<ProfileQuery> {
      return requester<ProfileQuery, ProfileQueryVariables>(ProfileDocument, variables, options) as Promise<ProfileQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;