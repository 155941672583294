import { Route } from 'react-router';

// Route component
export const Routes = (
  <>
    <Route index lazy={() => import('./pages/Verify')} />
    <Route path="participants/:userUuid" lazy={() => import('./pages/Participant')} />
    <Route path="participants" lazy={() => import('./pages/Participants')} />
    <Route path="reports" lazy={() => import('./pages/Reports')} />
    <Route path="catalog" lazy={() => import('./pages/Catalog')} />
  </>
);
