import Cookies from 'js-cookie';
import axios from 'axios';
import { getSdk } from './auth.generated';
import { fetcher } from '../../graphql-api/fetcher';

export const SSID = 'ssid';

export function initSSID(ssid: string) {
  if (ssid) {
    Cookies.set(SSID, ssid);
    axios.defaults.headers.common.Authorization = `Bearer ${ssid}`;
  } else {
    Cookies.remove(SSID);
    axios.defaults.headers.common.Authorization = '';
  }
}

export function setSSID(ssid: string): void {
  localStorage.setItem(SSID, ssid);
  initSSID(ssid);
}

export function getSSID(): string {
  return localStorage.getItem(SSID) || '';
}

export const api = getSdk(fetcher);
